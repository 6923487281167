import dayjs from 'dayjs'
import { checkSchedulePeriod } from 'features/kdt/utils/checkSchedulePeriod'
import { IApplyStatusList } from 'features/klass/components/HomeApplyPeriodBadge'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

interface ISchoolData {
  data: { [key: string]: string | any }
}

const MonthlyScheduleCard = ({ data }: ISchoolData) => {
  const router = useRouter()
  const status = checkSchedulePeriod(data.applyStartedAt, data.applyEndedAt, data.label)
  const nowDate = dayjs().format('YYYY-MM-DD')
  const endedDate = dayjs(data.applyEndedAt).format('YYYY-MM-DD')
  const expirationDate = useMemo(() => dayjs(endedDate).diff(nowDate, 'day'), [endedDate, nowDate])

  const applyStatusList: IApplyStatusList = {
    early: {
      label: '사전알림신청',
      style: 'border-teal-500 text-emerald-600',
    },
    apply: {
      label: '모집중',
      style: 'border-blue-500 text-blue-700',
    },
    applyDeadline: {
      label: '마감임박',
      style: 'border-blue-500 text-blue-700',
    },
    applyEarly: {
      label: '모집예정',
      style: 'border-gray-500 text-gray-650',
    },
  }

  const { label, style } = applyStatusList[status]
  return (
    <article
      onClick={() => {
        if (label !== '모집예정') {
          void router.push(`/school/${data.id as string}`).then(() => window.scrollTo(0, 0))
        }
      }}
      className={`${
        label !== '모집예정'
          ? 'cursor-pointer lg:hover:shadow-[0px_4px_20px_0px_rgba(0,0,0,0.08)]'
          : 'cursor-default'
      } mb-4 box-border flex h-[100px] items-center overflow-hidden rounded-lg border border-trueGray-200 bg-white last:mb-0 md:h-[156px] md:rounded-xl lg:mb-0`}>
      <div className="w-[134px] shrink-0 overflow-hidden md:w-52">
        <img
          src={data.image ?? data.thumbnails.month}
          alt="스쿨 썸네일"
          className="w-full max-w-none"
        />
      </div>
      <div className="overflow-hidden p-4">
        <div>
          <div
            className={`${style} inline-flex items-center rounded border px-[6px] py-[2px] text-xs font-normal md:text-sm lg:px-2 lg:py-1 lg:text-sm lg:font-semibold`}>
            {label}
            {_.isEqual(status, 'applyDeadline') &&
              ` D-${_.isEqual(expirationDate, 0) ? 'day' : expirationDate}`}
          </div>
        </div>
        <h4 className="mb-2 mt-1 text-sm font-semibold lg:mb-1 lg:mt-2 lg:text-xl lg:font-bold">
          {data.title ?? data.name}
        </h4>
        <p className="mb-1 hidden break-keep text-lg lg:block xl:truncate">
          {data.subtitle ?? data.description}
        </p>
        <div className="text-xs text-trueGray-500 lg:text-base">
          {label !== '모집예정' ? (
            <>
              {dayjs(data.applyStartedAt).format('YY.MM.DD')} ~{' '}
              {dayjs(data.applyEndedAt).format('YY.MM.DD')}{' '}
              <span className="hidden lg:inline-block">까지</span>
            </>
          ) : (
            <>
              {dayjs(data.applyStartedAt).format('YY년 M월')}{' '}
              <span className="inline-block">모집 예정</span>
            </>
          )}
        </div>
      </div>
    </article>
  )
}

export default MonthlyScheduleCard
