import { ChevronLeftIcon, ChevronRightIcon, RefreshIcon } from '@heroicons/react/outline'
import { LoadingOverlay, Tabs } from '@mantine/core'
import dayjs, { Dayjs } from 'dayjs'
import { useKDTSchedule } from 'features/kdt/queries/useKDTSchedule'
import { IKDTPlans } from 'features/kdt/types'
import { IKlass } from 'features/klass/types'
import { sortedList } from 'features/showroom/utils'
import _ from 'lodash'
import { scheduleSchoolList } from 'mocks/kdt'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'

import MonthlyScheduleCard from './MonthlyScheduleCard'
import SchoolScheduleCard from './SchoolScheduleCard'

export interface ISchoolToggle {
  id: string
  label: string
  active: boolean
  bgColor: string
  subTitle: string
  thumbnails: {
    pc: string
    mobile: string
  }
}

interface ISchedule {
  showroom: IKlass[]
}

export interface IKlassData {
  id: string
  title: string
  bgColor: string
  subTitle: string
  thumbnails: {
    pc: string
    mobile: string
  }
  data: Array<IKlass | IKDTPlans>
}

const KDTSchedule = ({ showroom }: ISchedule) => {
  const [currentDate, setCurrentDate] = useState<Dayjs>()
  const [curDateSchool, setCurDateSchool] = useState<Array<IKlass | IKDTPlans>>([]) // 월별 데이터 (6개)
  const [fullDateSchool, setFullDateSchool] = useState<Array<IKlass | IKDTPlans>>([]) // 월별 모든 데이터
  const [curKlassData, setCurKlassData] = useState<IKlassData[]>() // 클래스별 데이터
  const [klassToggle, setKlassToggle] = useState<ISchoolToggle[]>(scheduleSchoolList)
  const [klassTabOrder, setKlassTabOrder] = useState<string[]>([])
  const [moreData, setMoreData] = useState(false)
  const [filters, setFilters] = useState({})
  const [activeTab, setActiveTab] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { data: KDTPlansData } = useKDTSchedule(filters)
  const schoolActiveList = useMemo(() => _.filter(klassToggle, { active: true }), [klassToggle])
  const curMonth = useMemo(() => Number(currentDate?.get('month')), [currentDate])
  const showroomFilter = useMemo(
    () => [...sortedList(showroom, 'apply'), ...sortedList(showroom, 'early')],
    [showroom],
  )
  const schoolIdDuplicateFilter = useMemo(
    () =>
      _.differenceBy(KDTPlansData, showroomFilter, 'id').map(klass => ({
        ...klass,
        label: 'applyEarly',
      })),
    [KDTPlansData, showroomFilter],
  )
  const allData = useMemo(() => [...showroomFilter, ...schoolIdDuplicateFilter], [
    schoolIdDuplicateFilter,
    showroomFilter,
  ])

  const onClickNextMonth = useCallback(() => {
    if (currentDate?.format('YYYY-M') === '2024-12') return
    setCurrentDate(currentDate?.set('month', curMonth + 1))
    setMoreData(false)
  }, [curMonth, currentDate])

  const onClickPrevMonth = useCallback(() => {
    const today = dayjs().format('YYYY-M')
    if (today === currentDate?.format('YYYY-M')) return
    setCurrentDate(currentDate?.set('month', curMonth - 1))
    setMoreData(false)
  }, [curMonth, currentDate])

  const onClickSchool = useCallback(
    (label, id) => {
      const result: ISchoolToggle[] = []
      klassToggle.forEach((v, index) => {
        result[index] = v
        if (v.label === label) {
          result[index] = {
            id: v.id,
            label: v.label,
            active: !v.active,
            bgColor: v.bgColor,
            subTitle: v.subTitle,
            thumbnails: { ...v.thumbnails },
          }
        }
      })
      setKlassTabOrder(_.xor(klassTabOrder, [id]))
      setKlassToggle(result)
    },
    [klassToggle, klassTabOrder],
  )

  useEffect(() => {
    const date = dayjs()
    setCurrentDate(date)
  }, [])

  // 월별 필터링
  useEffect(() => {
    const date = dayjs()
    let curDateSchool: Array<IKlass | IKDTPlans> = []

    if (!_.isEmpty(currentDate)) {
      if (date.format('YYYY년 MM월') === currentDate?.format('YYYY년 MM월')) {
        curDateSchool = allData.filter(
          v =>
            !_.isEmpty(v.applyStartedAt) &&
            !_.isEmpty(v.applyEndedAt) &&
            date.isBetween(v.applyStartedAt, v.applyEndedAt, 'year', '[]') &&
            date.isBetween(v.applyStartedAt, v.applyEndedAt, 'month', '[]'),
        )
      } else {
        const month = Number(currentDate?.get('month')) - 1
        let nextMonth = date.set('month', month + 1)
        if (currentDate?.get('year') === 2024) {
          nextMonth = nextMonth.set('year', 2024)
        }
        curDateSchool = allData.filter(
          v =>
            !_.isEmpty(v.applyStartedAt) &&
            !_.isEmpty(v.applyEndedAt) &&
            nextMonth.isBetween(v.applyStartedAt, v.applyEndedAt, 'year', '[]') &&
            nextMonth.isBetween(v.applyStartedAt, v.applyEndedAt, 'month', '[]'),
        )
      }
    }

    if (curDateSchool.length > 6) {
      setCurDateSchool(curDateSchool.slice(0, 6))
    } else {
      setCurDateSchool(curDateSchool)
    }
    setFullDateSchool(curDateSchool)
  }, [showroom, currentDate, KDTPlansData, allData])

  // 클래스별 필터링
  useEffect(() => {
    let result: IKlassData[] = []

    if (!_.isEmpty(allData)) {
      const data: { [key: string]: any }[] = []
      allData.forEach(klass => {
        schoolActiveList.forEach(v => {
          if (!klass.id.includes('plus')) {
            if (v.id === 'ai') {
              if (klass.id.includes('kdt-ai')) {
                data.push({
                  id: v.id,
                  title: v.label,
                  data: [{ ...klass }],
                  bgColor: v.bgColor,
                  subTitle: v.subTitle,
                  thumbnails: { pc: v.thumbnails.pc, mobile: v.thumbnails.mobile },
                })
              }
            } else if (klass.id.includes(v.id)) {
              data.push({
                id: v.id,
                title: v.label,
                data: [{ ...klass }],
                bgColor: v.bgColor,
                subTitle: v.subTitle,
                thumbnails: { pc: v.thumbnails.pc, mobile: v.thumbnails.mobile },
              })
            } else {
              data.push({
                id: v.id,
                title: v.label,
                data: [],
                bgColor: v.bgColor,
                subTitle: v.subTitle,
                thumbnails: { pc: v.thumbnails.pc, mobile: v.thumbnails.mobile },
              })
            }
          } else if (!klass.id.includes(v.id)) {
            data.push({
              id: v.id,
              title: v.label,
              data: [],
              bgColor: v.bgColor,
              subTitle: v.subTitle,
              thumbnails: { pc: v.thumbnails.pc, mobile: v.thumbnails.mobile },
            })
          } else if (klass.id.includes('plus') && v.id.includes('plus')) {
            if (klass.id.includes(v.id)) {
              data.push({
                id: v.id,
                title: v.label,
                data: [{ ...klass }],
                bgColor: v.bgColor,
                subTitle: v.subTitle,
                thumbnails: { pc: v.thumbnails.pc, mobile: v.thumbnails.mobile },
              })
            }
          }
        })
      })
      const arrayHashmap = data.reduce((acc, cur) => {
        if (acc[cur.id]) {
          acc[cur.id].data.push(...cur.data)
        } else {
          acc[cur.id] = { ...cur }
        }
        return acc
      }, {})
      result = Object.values(arrayHashmap)
    }
    result = _.sortBy(result, item => _.indexOf(klassTabOrder, item.id)).reverse()

    setCurKlassData(result)
  }, [showroom, schoolActiveList, KDTPlansData, allData, klassTabOrder])

  // 검색 필터링
  useEffect(() => {
    setFilters({ month: currentDate?.format('YYYY-MM') })
  }, [currentDate])

  useEffect(() => {
    const schoolIds = _.map(schoolActiveList, 'id')
    setFilters({ category: schoolIds.join(',') })
  }, [schoolActiveList])

  useEffect(() => {
    if (activeTab === 1) {
      setFilters({ month: currentDate?.format('YYYY-MM') })
    } else {
      setKlassToggle(scheduleSchoolList)
      setKlassTabOrder([])
      setFilters({})
    }
  }, [activeTab])

  // 클래스별 보기 로딩바
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 500)
  }, [schoolActiveList])

  return (
    <Tabs
      onTabChange={active => {
        setActiveTab(active)
      }}
      classNames={{
        tabsListWrapper:
          'p-0 rounded-full rounded-none bg-transparent border-none mt-8 mb-6 lg:my-8 mx-4 lg:mx-0',
        tabsList: '!mb-[-2px] lg:border-b lg:border-trueGray-200',
        tabControl:
          'transition-none h-[40px] font-normal text-base lg:text-xl w-auto px-0 text-trueGray-500 mr-4 lg:mr-6 last:mr-0',
        tabActive:
          'rounded-none bg-transparent !text-yellow-450 !font-semibold border-b-[2px] !border-yellow-450',
        body: 'pt-0',
      }}>
      <Tabs.Tab label="부트캠프별">
        <div className="box-border w-full px-4 lg:rounded-2xl lg:px-0 lg:py-0">
          <div className="flex items-center justify-between">
            <p className=" text-base font-semibold lg:text-xl">관심있는 부트캠프를 선택해주세요.</p>
            {!_.isEmpty(schoolActiveList) && (
              <button
                onClick={() => {
                  setKlassToggle(scheduleSchoolList)
                  setKlassTabOrder([])
                  setFilters({})
                }}
                className="flex items-center">
                <RefreshIcon className="hidden w-4 text-trueGray-500 md:block lg:w-6" />
                <span className="text-xs text-trueGray-500 underline md:ml-1 md:text-base md:font-medium md:no-underline lg:text-xl">
                  초기화
                </span>
              </button>
            )}
          </div>
          <div className="mt-4 flex flex-wrap gap-[10px] rounded-2xl border border-trueGray-200 bg-white px-4 py-4 lg:mt-6 lg:gap-4 lg:px-6 lg:py-6">
            {klassToggle.map(klass => (
              <button
                onClick={() => {
                  onClickSchool(klass.label, klass.id)
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'kdt_classlist_interest_click',
                      item_id: klass.id,
                      ...(sessionStorage.getItem('utmAndGclid') &&
                        JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
                    },
                  })
                }}
                key={`class-${klass.id}`}
                className={`${
                  klass.active ? 'border-yellow-450 bg-orange-50 text-yellow-450' : ''
                } rounded-md border border-trueGray-200 p-[6px] text-xs md:text-sm lg:px-3 lg:py-2 lg:text-lg`}>
                {klass.label}
              </button>
            ))}
          </div>
          {!_.isEmpty(curKlassData) && (
            <>
              {isLoading ? (
                <div className="relative min-h-[200px]">
                  <LoadingOverlay visible={true} overlayColor="#fff" />
                </div>
              ) : (
                <div className="mt-6 md:grid md:grid-cols-2 md:gap-6">
                  {curKlassData?.map((klass, index) => (
                    <SchoolScheduleCard key={`${klass.id}_${index}`} data={klass} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </Tabs.Tab>
      <Tabs.Tab label="월별로 보기">
        <div className="box-border w-full px-4 pb-4 lg:px-6 lg:pb-6">
          <div className="flex items-center justify-center">
            <button onClick={onClickPrevMonth}>
              <ChevronLeftIcon className="w-5 lg:w-6" />
            </button>
            <div className="mx-2 w-[138px] min-w-[138px] text-center text-lg font-semibold lg:text-2xl">
              {currentDate?.format('YYYY년 MM월')}
            </div>
            <button onClick={onClickNextMonth}>
              <ChevronRightIcon className="w-5 lg:w-6" />
            </button>
          </div>
          <div className="mt-4 lg:mt-2">
            {_.isEmpty(curDateSchool) ? (
              <div className="mt-4 flex flex-col items-center justify-center">
                <div className="w-[138px]">
                  <img src="/img/empty.png" alt="데이터 없음" />
                </div>
                <p className="mt-3 text-trueGray-500">해당 달에는 수강 가능한 클래스가 없습니다.</p>
              </div>
            ) : (
              <div
                className={`${
                  curDateSchool.length <= 1 ? '' : 'md:grid md:grid-cols-2 md:gap-6'
                } mt-4`}>
                {curDateSchool.map(v => (
                  <MonthlyScheduleCard key={v.id} data={v} />
                ))}
              </div>
            )}
            {fullDateSchool.length > 6 && (
              <button
                onClick={() => {
                  setMoreData(prev => !prev)
                  if (!moreData) {
                    setCurDateSchool(fullDateSchool)
                  } else {
                    setCurDateSchool(fullDateSchool.slice(0, 6))
                  }
                }}
                className="mx-auto mt-6 flex rounded-md border border-trueGray-300 bg-white px-10 py-3 text-sm font-semibold lg:px-20 lg:text-base">
                {moreData ? '접기' : '더보기'}
              </button>
            )}
          </div>
        </div>
      </Tabs.Tab>
    </Tabs>
  )
}

export default KDTSchedule
