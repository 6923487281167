import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const checkSchedulePeriod = (startedAt: string, endedAt: string, label?: string) => {
  const curDate = dayjs()
  const startDay = dayjs(startedAt)
  const endDay = dayjs(endedAt)

  let result = ''
  if (label) {
    return (result = 'applyEarly')
  } else {
    if (curDate.isBefore(startDay)) {
      return (result = 'early')
    }

    if (curDate.isBetween(startDay, endDay)) {
      if (endDay.diff(curDate, 'd') <= 7) {
        return (result = 'applyDeadline')
      }

      return (result = 'apply')
    }
  }

  return result
}
