import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IKDTPlans, IKDTPlansResponse } from '../types'

const fetchKDTScheduleData = async (filters: { [key: string]: string }) => {
  let result: AxiosResponse<IKDTPlansResponse[]> | undefined

  const queryString = Object.keys(filters).reduce((acc: any, cur: string) => {
    const value = filters[cur]
    if (value !== null && value !== '') {
      acc.push(`${cur}:${value}`)
    }
    return acc
  }, [])

  if (!_.isEmpty(filters) && filters?.category !== '') {
    result = await axios.get<IKDTPlansResponse[]>(
      `${window.apiEndpoint}/showroom/v1/kdt-plans?filters=${queryString[0] as string}`,
    )
  }
  return convertCaseList(result?.data, ConvertType.CAMEL)
}

export const useKDTSchedule = (filters: { [key: string]: string }) => {
  return useQuery<IKDTPlans[]>({
    queryKey: ['kdtSchedule', filters],
    queryFn: () => fetchKDTScheduleData(filters),
    retry: 2,
  })
}
